import { createStore } from 'vuex';
import * as auth from './modules/auth';
import axios from 'axios';
import api from '@/store/modules/api';

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async get_skills ({ commit }, form) {
      try {
        const url = api.skills;
        const { data } = await axios(url);
      } catch (err) {}
    },
    async save_form ({ commit }, data) {
      try {
        console.log(data);
        const url = api.skills;
        // const {data} = await axios(url)
      } catch (err) {
        console.log(err);
      }
    }
  },
  modules: {
    auth
  }
});
